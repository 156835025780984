import React, { useState } from 'react';
import { Layout, Menu, Space, Dropdown, Avatar } from 'antd';
import PropTypes from 'prop-types';
import { DeploymentUnitOutlined, BarChartOutlined, UserOutlined, ControlOutlined, EnvironmentOutlined, AlertOutlined } from '@ant-design/icons';
import { Link, useLocation, useHistory } from 'react-router-dom';
import AuthService from '../services';
import urls from '../config/urls';

const { Header, Content, Sider } = Layout;


function getItem(label, key, icon, children) {
    return {
        key,
        icon,
        children,
        label,
    };
}

const getAvatarInitials = (name) => {
    return (name && name.trim().length > 2) ? name.slice(0, 2).toUpperCase() : "E"
  }
  

let items = [
    getItem((
        <Link to="/participants">
          Individual Overview
        </Link>
      ), '/participants', <UserOutlined />),
    // getItem((
    //     <Link to="/participants/demographics">
    //       Partcipant Demographics
    //     </Link>
    //   ), "/participants/demographics", <BarChartOutlined />),
    // getItem((
    //     <Link to="/sites">
    //       Site Overview
    //     </Link>
    //   ), '/sites', <EnvironmentOutlined />),
    // getItem((
    //     <Link to="/network">
    //       Network Overview
    //     </Link>
    //   ),  '/network', <DeploymentUnitOutlined />),
    // getItem((
    //     <Link to="/alerts">
    //       Alerts
    //     </Link>
    //   ), '/alerts', <AlertOutlined />),
];


const SidebarLayout = ({ children }) => {
    const [collapsed, setCollapsed] = useState(false);
    const location = useLocation();
    const history = useHistory();
    const host = process.env.NODE_ENV === 'development' ? urls.localHost : urls.apiHost;
    const isLoggedIn = AuthService.isLoggedIn();
    const currentUser = AuthService.getUser();
    if (AuthService.isAdmin()) {
        let exists = items.some((item) => item.key === '/admin');
        if (!exists) {
          items.push(getItem((
            <Link to="/admin">
              Admin Overview
            </Link>
          ), '/admin', <ControlOutlined />),)
        }
    }

    const menuItemsConfig = {
        name: {
          key: 'name', 
          label: `User: ${currentUser && currentUser.name}`,
          handler: async () => {}
        },
        site: {
          key: 'site', 
          label: `Site: ${currentUser && currentUser.siteName}`,
          handler: async () => {}
        },
        logout: {
          key: 'logout',
          label: 'Logout',
          handler: async () => {
            let logoutResponse = await fetch(`${host}/logout`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: AuthService.getToken(),
              },
              body: JSON.stringify({ something: 'something' }),
            });
    
            logoutResponse = await logoutResponse.json();
    
            if (logoutResponse && logoutResponse.loggedOut) {
              AuthService.logout();
              history.push('/login');
            }
          },
        },
      };
    
      // Make call to logout...await....if successful then  redirect to login
      const menu = (
        <Menu onClick={async ({ key }) => menuItemsConfig[key].handler()}>
          {Object.keys(menuItemsConfig).map((menuItemKey) => {
            return <Menu.Item key={menuItemKey}>{menuItemsConfig[menuItemKey].label}</Menu.Item>;
          })}
        </Menu>
      );

    return (
        <Layout style={{ minHeight: '100vh' }}>

            {
                isLoggedIn && (
                    <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} width={'250px'}>
                        <div>
                            <Space direction="horizontal" style={{color: '#fff', padding: '15px', fontSize: '1.5em', width: '100%', justifyContent: 'center'}}>
                            Epidash
                            </Space>
                        </div>
                        <Menu theme="dark" selectedKeys={[location.pathname]} defaultSelectedKeys={['/sites']} mode="inline" items={items} />
                    </Sider>
                )
            }
            {
                isLoggedIn ? (
                    <Layout>
                        <Header style={{backgroundColor: 'transparent'}}>
                            <div style={{ float: 'right' }}>
                                <Dropdown overlay={menu} disabled={!isLoggedIn}>
                                    <Avatar
                                        style={{ float: 'right', marginTop: '10px', backgroundColor: '#FF7A45' }}
                                        size="large"
                                    >
                                        {getAvatarInitials(currentUser.name)}
                                    </Avatar>
                                </Dropdown>
                            </div>
                        </Header>
                        <Content>
                            {children}
                        </Content>
                    </Layout>
                ) : (
                    <Content>{children}</Content>
                )
            }
        </Layout>
    )

}

SidebarLayout.propTypes = {
    children: PropTypes.element.isRequired,
};

export default SidebarLayout