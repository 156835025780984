import moment from 'moment';
import jwt from 'jsonwebtoken';

// Let's create an auth service so that we can store keep track of if the user is logged in
// This can be switched out later to 3rd party auth and the iterface stay the same so other
// parts of the app doesn't have to change.
const setLocalStorage = (authObject) => {
  const expires = moment.utc(authObject.expiresIn);
  localStorage.setItem('token', authObject.token); // includes the Bearer
  localStorage.setItem('expires', JSON.stringify(expires.valueOf()));
};

const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('expires');
};

const getExpiration = () => {
  const expiration = localStorage.getItem('expires');
  const expiresAt = JSON.parse(expiration);
  const expirationMoment = moment.utc(expiresAt);
  return expirationMoment;
};

const isLoggedIn = () => {
  const currentMoment = moment.utc();
  return currentMoment.isBefore(getExpiration());
};
const isLoggedOut = () => !isLoggedIn();
const getToken = () => localStorage.getItem('token');

const updatePassword = () => {};

const getUser = () => {
  let decodedToken = undefined

  const userToken = getToken();
  if (userToken && userToken.includes("Bearer ")) {
    const tokens = userToken.split("Bearer ")
    if (tokens && tokens.length > 1) {
      decodedToken = jwt.decode(tokens[1]);
    }
  }
  return decodedToken;
}

const hasRole = (roles) => {
  const currentUser = getUser()
  return currentUser && roles.includes(currentUser.role.toLowerCase());
}

const isAdmin = () => {
  const currentUser = getUser()
  const adminRoles = ["super-admin", "hub-approving-admin", "hub-admin", "site-admin"]
  return currentUser && adminRoles.includes(currentUser.role.toLowerCase());
}

const ROLES = {
  SUPER_ADMIN: ["Super Admin", "super-admin"],
  HUB_APPROVING_ADMIN: ["Hub Approving Admin", "hub-approving-admin"],
  HUB_ADMIN: ["Hub Admin", "hub-admin"],
  SITE_ADMIN: ["Site Admin", "site-admin"],
  HUB_MEMBER: ["Hub Member", "hub-member"],
  SITE_MEMBER: ["Site Member", "site-member"]
}

const getRoles = () => {
  if (hasRole(['super-admin', 'hub-approving-admin'])) {
    return [ROLES.HUB_APPROVING_ADMIN, ROLES.HUB_ADMIN, ROLES.SITE_ADMIN, ROLES.HUB_MEMBER, ROLES.SITE_MEMBER]
  }
  if (hasRole(['hub-admin'])) {
    return [ROLES.HUB_ADMIN, ROLES.SITE_ADMIN, ROLES.HUB_MEMBER, ROLES.SITE_MEMBER]
  }
  if (hasRole(['site-admin'])) {
    return [ROLES.SITE_ADMIN, ROLES.SITE_MEMBER]
  }
  if (hasRole(['hub-member'])) {
    return [ROLES.HUB_MEMBER, ROLES.SITE_MEMBER]
  }
  if (hasRole(['site-member'])) {
    return [ROLES.SITE_MEMBER]
  }
}

export default {
  setLocalStorage,
  logout,
  getExpiration,
  isLoggedIn,
  isLoggedOut,
  getToken,
  updatePassword,
  getUser,
  isAdmin,
  hasRole,
  getRoles
};
