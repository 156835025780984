import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloClient, InMemoryCache, createHttpLink, ApolloProvider, ApolloLink, from } from '@apollo/client';
import AuthService from './services';
import App from './App';
import urls from './config/urls';
import { onError } from "@apollo/client/link/error";
import { message as toast } from 'antd';

import './GlobalStyles.css'

const host = process.env.NODE_ENV === 'development' ? urls.localHost : urls.apiHost;
const httpLink = createHttpLink({
  uri: `${host}/graphql`,
});

const authLink = new ApolloLink((operation, forward) => {
  // get the authentication token from auth service if it exists
  const token = AuthService.getToken();
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: token,
    }
  }));

  return forward(operation);
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      toast.error(message);
    });
  if (networkError) toast.error(networkError);
});

// Create an Apollo Link that adds the current URL to each request
const addUrlLink = new ApolloLink((operation, forward) => {
  // Use window.location to get the current URL
  const pageUrl = window.location.pathname;

  // Directly modify the operation.variables object
  operation.variables = {
    ...operation.variables,
    pageUrl // Add the current URL to the variables
  };

  console.log(`Page Url: ${pageUrl} Variables: ${JSON.stringify(operation.variables, null, 4)}`);

  // Call the next link in the middleware chain
  return forward(operation);
});

// Create an Apollo Link that adds the current URL to each request
const checkUrlLink = new ApolloLink((operation, forward) => {

  console.log(`Variables ARE: ${JSON.stringify(operation.variables, null, 4)}`);

  // Call the next link in the middleware chain
  return forward(operation);
});


const client = new ApolloClient({
  link: from([
    authLink,   // Handle authentication headers first
    addUrlLink, // Add page URL next
    errorLink,  // Handle errors after the URL is added
    checkUrlLink,
    httpLink    // Finally, send the request
  ]),
  cache: new InMemoryCache(),
});


ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.querySelector('#root'),
);
