import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import SidebarLayout from './layout/SidebarLayout';
import RouteWithSubRoutes from './RouteWithSubRoutes';

const Login = lazy(() => import('./pages/login/Login'));
const SiteOverview = lazy(() => import('./pages/site/SiteOverview'));
const NetworkOverview = lazy(() => import('./pages/network/NetworkOverview'));
const AlertsOverview = lazy(() => import('./pages/alerts/AlertsOverview'));
const AdminOverview = lazy(() => import('./pages/admin/AdminOverview'));
const ParticipantOverview = lazy(() => import('./pages/participant/ParticipantOverview'));
const ParticipantVisitsOverview = lazy(() => import('./pages/participant-visit/ParticipantVisits'));
const PasswordReset = lazy(() => import('./pages/password/PasswordReset'));
const DemographicsOverview = lazy(() => import('./pages/participant/demographics/DemographicsOverview'));
const ParticipantDetails = lazy(() => import('./pages/beta/ParticipantDetails'));

const routes = [
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/participants',
    component: ParticipantOverview,
  },
  {
    path: '/participants/demographics',
    component: DemographicsOverview,
  },
  {
    path: '/participants/:participantId/visits',
    component: ParticipantVisitsOverview,
  },
  {
    path: '/sites',
    component: SiteOverview,
  },
  {
    path: '/network',
    component: NetworkOverview,
  },
  {
    path: '/admin',
    component: AdminOverview,
  },
  {
    path: '/auth/password-reset',
    component: PasswordReset,
  },
  {
    path: '/beta',
    component: ParticipantDetails
  },
  {
    path: '/individual/:individualId/details',
    component: ParticipantDetails
  },
  {
    path: '/alerts',
    component: AlertsOverview
  }
];

const AppRouter = () => {
  return (
    <Router>
      <SidebarLayout>
        <Suspense fallback={<div className="lazy-loading">Loading...</div>}>
          {routes.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
          ))}
          <Route exact path="/">
            <ParticipantOverview />
          </Route>
        </Suspense>
      </SidebarLayout>
    </Router>
  );
};

export default AppRouter;
